import { message } from "antd";
import { uploadImage } from "../api";
// const [uploading, setUploading] = useState(false);

export const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('Vui lòng tải lên tệp tin có dang JPG/PNG !');
    }
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
        message.error('Vui lòng tải lên ảnh có kích thước nhỏ hơn 10MB!');
    }
    return isJpgOrPng && isLt2M;
};


export const handleUpload = async (info,dataUpdate,setDateUpdate,setImageUrl,setUploading) => {
    const checkUpload = beforeUpload(info.file)
    if(checkUpload) {
     setUploading(true);
     try {
         const response = await uploadImage(info.file);
         console.log(response);
         setImageUrl(response);
         setDateUpdate({ ...dataUpdate, image: response })
     } catch (error) {
         console.error('Lỗi khi tải ảnh lên:', error);
         message.error('Đã xảy ra lỗi, vui lòng thử lại sau!');
     } finally {
         setUploading(false);
     }
    }
 };