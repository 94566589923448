const dataNews=[
    {
        id:"1",
        "title":"Phòng khám sản phụ khoa Dr.Marie chính thức có trên Medpro",
        "image":"https://medpro.vn/_next/image?url=https%3A%2F%2Fcdn.medpro.vn%2Fprod-partner%2Fbd338754-9df8-4abf-92b0-91963fbcf598-web_banner_tin_taaa(c)c_(1).webp&w=1200&q=75",
        "description":"Từ tháng 04/2024, người bệnh có thể dễ dàng đặt lịch khám chuyên khoa sản, phụ khoa tại Phòng khám sản phụ khoa Dr.Marie qua nền tảng Medpro - Đặt khám nhanh.",
        "author":"Vũ Tiến Khoái",
        "createdAt":"2024-10-10"
    },
    {
        id:"2",
        "title":"Phòng khám sản phụ khoa Dr.Marie chính thức có trên Medpro",
        "image":"https://medpro.vn/_next/image?url=https%3A%2F%2Fcdn.medpro.vn%2Fprod-partner%2Fbd338754-9df8-4abf-92b0-91963fbcf598-web_banner_tin_taaa(c)c_(1).webp&w=1200&q=75",
        "description":"Từ tháng 04/2024, người bệnh có thể dễ dàng đặt lịch khám chuyên khoa sản, phụ khoa tại Phòng khám sản phụ khoa Dr.Marie qua nền tảng Medpro - Đặt khám nhanh.",
        "author":"Vũ Tiến Khoái",
        "createdAt":"2024-10-10"
    },
    {
        id:"3",
        "title":"Phòng khám sản phụ khoa Dr.Marie chính thức có trên Medpro",
        "image":"https://medpro.vn/_next/image?url=https%3A%2F%2Fcdn.medpro.vn%2Fprod-partner%2Fbd338754-9df8-4abf-92b0-91963fbcf598-web_banner_tin_taaa(c)c_(1).webp&w=1200&q=75",
        "description":"Từ tháng 04/2024, người bệnh có thể dễ dàng đặt lịch khám chuyên khoa sản, phụ khoa tại Phòng khám sản phụ khoa Dr.Marie qua nền tảng Medpro - Đặt khám nhanh.",
        "author":"Vũ Tiến Khoái",
        "createdAt":"2024-10-10"
    },
    {
        id:"4",
        "title":"Phòng khám sản phụ khoa Dr.Marie chính thức có trên Medpro",
        "image":"https://medpro.vn/_next/image?url=https%3A%2F%2Fcdn.medpro.vn%2Fprod-partner%2Fbd338754-9df8-4abf-92b0-91963fbcf598-web_banner_tin_taaa(c)c_(1).webp&w=1200&q=75",
        "description":"Từ tháng 04/2024, người bệnh có thể dễ dàng đặt lịch khám chuyên khoa sản, phụ khoa tại Phòng khám sản phụ khoa Dr.Marie qua nền tảng Medpro - Đặt khám nhanh.",
        "author":"Vũ Tiến Khoái",
        "createdAt":"2024-10-10"
    },
    {
        id:"5",
        "title":"Phòng khám sản phụ khoa Dr.Marie chính thức có trên Medpro",
        "image":"https://medpro.vn/_next/image?url=https%3A%2F%2Fcdn.medpro.vn%2Fprod-partner%2Fbd338754-9df8-4abf-92b0-91963fbcf598-web_banner_tin_taaa(c)c_(1).webp&w=1200&q=75",
        "description":"Từ tháng 04/2024, người bệnh có thể dễ dàng đặt lịch khám chuyên khoa sản, phụ khoa tại Phòng khám sản phụ khoa Dr.Marie qua nền tảng Medpro - Đặt khám nhanh.",
        "author":"Vũ Tiến Khoái",
        "createdAt":"2024-10-10"
    },
    {
        id:"6",
        "title":"Phòng khám sản phụ khoa Dr.Marie chính thức có trên Medpro",
        "image":"https://medpro.vn/_next/image?url=https%3A%2F%2Fcdn.medpro.vn%2Fprod-partner%2Fbd338754-9df8-4abf-92b0-91963fbcf598-web_banner_tin_taaa(c)c_(1).webp&w=1200&q=75",
        "description":"Từ tháng 04/2024, người bệnh có thể dễ dàng đặt lịch khám chuyên khoa sản, phụ khoa tại Phòng khám sản phụ khoa Dr.Marie qua nền tảng Medpro - Đặt khám nhanh.",
        "author":"Vũ Tiến Khoái",
        "createdAt":"2024-10-10"
    },
    {
        id:"7",
        "title":"Phòng khám sản phụ khoa Dr.Marie chính thức có trên Medpro",
        "image":"https://medpro.vn/_next/image?url=https%3A%2F%2Fcdn.medpro.vn%2Fprod-partner%2Fbd338754-9df8-4abf-92b0-91963fbcf598-web_banner_tin_taaa(c)c_(1).webp&w=1200&q=75",
        "description":"Từ tháng 04/2024, người bệnh có thể dễ dàng đặt lịch khám chuyên khoa sản, phụ khoa tại Phòng khám sản phụ khoa Dr.Marie qua nền tảng Medpro - Đặt khám nhanh.",
        "author":"Vũ Tiến Khoái",
        "createdAt":"2024-10-10"
    },
   
]
export {dataNews}