import React, { useEffect, useState } from 'react';
import null_data from '../img/null-data.webp';
import './css/phieu_kham.css';
import { FaBriefcaseMedical, FaHandHoldingMedical, FaHospitalAlt } from 'react-icons/fa';
import { MdOutlineAccessTimeFilled, MdOutlineDateRange } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { getLichSuKhamDaDat } from '../api';
import { message } from 'antd';

export default function Da_Thanh_Toan({ inforUser }) {
    const id_user = localStorage.getItem("idUser");
    const [data, setData] = useState([]);
    const [inforBacSi, setInforBacSi] = useState([]);
    const [inforBenhVien, setInforBenhVien] = useState([]);
    const [inforChuyenKhoa, setInforChuyenKhoa] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getLichSuKhamDaDat(id_user);
                console.log(response);
                setData(response?.Data || []);
                setInforBacSi(response?.InforBacSi || []);
                setInforBenhVien(response?.InforBenhVien || []);
                setInforChuyenKhoa(response?.InforChuyenKhoa || []);
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchData();
    }, [id_user]);

    return (
        <div>
            {data.length > 0 ? (
                data.map((appointment, index) => (
                    <div key={appointment.id} style={{ padding: '20px' }} className="box_profile">
                        <div className="userName_User">
                            <h2>Quốc Thắng</h2>
                        </div>

                        <div className="line_phieu_kham"></div>
                        <div style={{ justifyContent: 'space-between', alignItems: 'flex-start' }} className="infor_user">
                            <div className="infor_user_text">
                                <p>
                                    <span>
                                        <FaHospitalAlt />
                                    </span>
                                    <span
                                        style={{
                                            color: "rgb(0, 181, 241)",
                                            fontWeight: '700',
                                            fontSize: '18px',
                                            textTransform: 'uppercase'
                                        }}>
                                        {inforBenhVien[0]?.name || 'Chưa cập nhật'}
                                    </span>
                                </p>
                                <p>
                                    <span>
                                        <FaBriefcaseMedical />
                                    </span>
                                    Chuyên khoa :
                                    <span>
                                        {inforChuyenKhoa[index]?.name || 'Chưa cập nhật'}
                                    </span>
                                </p>
                                <p>
                                    <span>
                                        <FaHandHoldingMedical />
                                    </span>
                                    Bác sĩ :
                                    <span>
                                        {inforBacSi[index]?.name || 'Chưa cập nhật'}
                                    </span>
                                </p>
                                <p>
                                    <span>
                                        <MdOutlineDateRange />
                                    </span>
                                    Ngày Khám :
                                    <span>
                                        {appointment.appointmentDate || 'Chưa cập nhật'}
                                    </span>
                                </p>
                                <p>
                                    <span>
                                        <MdOutlineAccessTimeFilled />
                                    </span>
                                    Giờ khám :
                                    <span>
                                        {appointment.timeSlot || 'Chưa cập nhật'}
                                    </span>
                                </p>
                            </div>
                            <div className="box_profile_avatar">
                                <div className="btn_phieu_kham">
                                    <Link to={`/chi-tiet-phieu-kham/${appointment.id}`}>
                                        Đặt khám thành công
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            ) : (
                <div className="null_data">
                    <h2>Bạn chưa có phiếu khám nào</h2>
                    <img src={null_data} alt="No data" />
                </div>
            )}
        </div>
    );
}
