import React, { useEffect, useState } from 'react'
import { Avatar, Button, Modal, Col, Row, Flex, Upload, Input, ConfigProvider, message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import '../../../css/admin/Insert_admin.css'
import { handleUpload } from '../../../Common/checkUpload';
import { getAllBenhVien, getCurentUser, updateUser } from '../../../api';

export default function ModalHopital({ openModal, setOpenModal, dataHopitals, dataHopital, setDataHospital ,page}) {
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState();
    const [uploading, setUploading] = useState(false);

    const [dataUpdate, setDateUpdate] = useState({
        name: "",
        diaChi: "",
        sdt: "",
        avatar: "",
        email: ""

    })
    const handleChange = async (info) => {
        handleUpload(info,dataUpdate,setDateUpdate,setImageUrl,setUploading)
      };
    const handleOk =async () => {
        const response = await updateUser(dataHopital.id, dataUpdate)
        console.log(response)
        message.success(response.message)
        setOpenModal(false);
        const data = await getAllBenhVien({ page: page })
    setDataHospital(data?.benhvien?.rows)
    };
    const handleCancel = () => {
        setOpenModal(false);
    };
    const handleChangeDataUser = (key, value) => {
        if (value) {
            setDateUpdate(prev => ({
                ...prev,
                [key]: value
            }))
        }
    }
    const uploadButton = (
        <button
            style={{
                border: 0,
                background: 'none',

            }}
            type="button"
        >
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div
                style={{
                    marginTop: 8,

                }}
            >
                Upload
            </div>
        </button>
    );
    return (
        <ConfigProvider
            theme={{
                components: {
                    Modal: {
                        contentBg: "#53B3FE",
                        headerBg: "#53B3FE"
                    },
                },
            }}
        >
            <Modal
                open={openModal}
                title="Chỉnh sửa nội dung bệnh viện"
                onOk={handleOk}
                onCancel={handleCancel}
                footer={(_, { OkBtn, CancelBtn }) => (
                    <>

                        <CancelBtn />
                        <OkBtn />
                    </>
                )}
                // footer={null}
                style={{ minWidth: '800px', borderRadius: '20px' }}

            >
                <Row>
                    <Col span={8} style={{ padding: 10, display: 'flex', flexDirection: 'column', alignItems: 'center' }}  >
                        <Flex vertical className='form_InsertHopital' >
                            <p className='lable_InsertHopital' > Nhập địa chỉ email<sup>*</sup></p>
                            <Input
                                placeholder={dataHopital?.email}
                                value={dataUpdate.email}
                                onChange={(e) => handleChangeDataUser("email", e.target.value)}
                                className='inout_InsertHopital'

                            />
                        </Flex>
                        <Flex vertical className='form_InsertHopital'>
                            <p className='lable_InsertHopital' >Tên bệnh viện<sup>*</sup></p>
                            <Input
                                value={dataUpdate.name}
                                placeholder={dataHopital?.name}
                                className='inout_InsertHopital'
                                onChange={(e) => handleChangeDataUser("name", e.target.value)}
                            />
                        </Flex>
                    </Col>
                    <Col span={8} style={{ padding: 10, display: 'flex', flexDirection: 'column', alignItems: 'center' }}   >
                        <Flex vertical className='form_InsertHopital' >
                            <p className='lable_InsertHopital'  >Nhập số điện thoại<sup>*</sup></p>
                            <Input
                                value={dataUpdate.sdt}
                                placeholder={dataHopital?.sdt}
                                className='inout_InsertHopital'
                                onChange={(e) => handleChangeDataUser("sdt", e.target.value)}
                            />
                        </Flex>
                        <Flex vertical className='form_InsertHopital'  >
                            <p className='lable_InsertHopital'  >Nhập địa chỉ bệnh viện<sup>*</sup></p>
                            <Input
                                value={dataUpdate.diaChi}
                                placeholder={dataHopital?.diaChi}
                                className='inout_InsertHopital'
                                onChange={(e) => handleChangeDataUser("diaChi", e.target.value)}
                            />
                        </Flex>


                    </Col>
                    <Col span={8}>
                        <Flex gap="middle" className='customUpload' style={{ marginTop: '20px', height: '120px' }} justify='center'>
                            <ConfigProvider
                                theme={{
                                    token: {

                                    },
                                }}
                            >
                                <Upload
                                    name="avatar"
                                    listType="picture-circle"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    beforeUpload={() => false}
                                    onChange={handleChange}
                                    size="large"
                                    loading={uploading}
                                >
                                    {imageUrl ? (
                                        <img
                                            src={imageUrl}
                                            alt="avatar"
                                            style={{
                                                width: '100%',
                                            }}
                                        />
                                    ) : (
                                        uploadButton
                                    )}
                                </Upload>
                            </ConfigProvider>

                        </Flex>
                    </Col>

                </Row>
                {/* <p>{dataHopital}</p> */}
                {/* <p>{dataHopital?.name}</p>
            <p>{dataHopital?.sdt}</p>
            <Avatar src={`${dataHopital?.avatar}`} />
            <p>{dataHopital?.diaChi}</p>
            <p>{dataHopital?.email}</p> */}
            </Modal>

        </ConfigProvider>
    )
}
