import React, { useEffect } from 'react'
import { datlich, vnPay_Return } from '../api';
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';
import io from 'socket.io-client';

export default function VnPay_return() {
    const searchParams = new URLSearchParams(window.location.search.substring(1));
    const vnpParams = {
        vnp_Amount: searchParams.get('vnp_Amount'),
        vnp_BankCode: searchParams.get('vnp_BankCode'),
        vnp_CardType: searchParams.get('vnp_CardType'),
        vnp_OrderInfo: searchParams.get('vnp_OrderInfo'),
        vnp_PayDate: searchParams.get('vnp_PayDate'),
        vnp_ResponseCode: searchParams.get('vnp_ResponseCode'),
        vnp_TmnCode: searchParams.get('vnp_TmnCode'),
        vnp_TransactionNo: searchParams.get('vnp_TransactionNo'),
        vnp_TransactionStatus: searchParams.get('vnp_TransactionStatus'),
        vnp_TxnRef: searchParams.get('vnp_TxnRef'),
        vnp_SecureHash: searchParams.get('vnp_SecureHash')
    };
    const scheduleId = localStorage.getItem('scheduleId')
    const idDoctor = localStorage.getItem('idDoctor')
    const id_user = localStorage.getItem('idUser')
    const socket = io('http://13.212.207.19:5000');
const navigate = useNavigate()
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await vnPay_Return(vnpParams);
                console.log(response);
                if (response.code == "00") {
                    message.success('Thanh toán thành công !!!')
                    const response = await datlich(scheduleId, id_user)
                    localStorage.removeItem('scheduleId');
                    console.log(response)
                    navigate('/')
                }
                if(response.code == "24"){
                    message.error('Giao dịch không thành công do: Khách hàng hủy giao dịch')
                    
                }
                if(response.code == "07"){
                    message.error('Trừ tiền thành công. Giao dịch bị nghi ngờ (liên quan tới lừa đảo, giao dịch bất thường).')
                }
                if(response.code == "09"){
                    message.error('Giao dịch không thành công do: Thẻ/Tài khoản của khách hàng chưa đăng ký dịch vụ InternetBanking tại ngân hàng.')
                }
                if(response.code == "10"){
                    message.error('Giao dịch không thành công do: Đã hết hạn chờ thanh toán. Xin quý khách vui lòng thực hiện lại giao dịch.')
                }
                else {
                    socket.emit('cancelScheduleSelection', { userId: id_user, doctorId: idDoctor });
                    localStorage.removeItem('idDoctor');
                    localStorage.removeItem('scheduleId');
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchData();
    }, [])
    return (
        <div>
           Loading...

        </div>
    )
}
