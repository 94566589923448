import React, { useEffect, useState } from 'react'
import '../../../css/admin/Dashboard.css'
import { Avatar, Col, Tooltip, Flex, ConfigProvider, Table} from 'antd'
import { FileOutlined, CalendarOutlined, FileExcelOutlined, FileAddOutlined } from '@ant-design/icons';
import ChartAdmin from '../../../componnets/chart/chartAdmin';
import { Link } from 'react-router-dom';
import { getAllBenhVien, getAllBenhVienLimit5, getAllLichSuKhamStatus, getAllNews, getAllUser, getCurent } from '../../../api';
export default function DashboarAdmin() {
  const idAdmin = localStorage.getItem("idUser")
  const [dataHospital, setDataHospital] = useState([])
  const [dataInfAdmin, setDataInfAdmin] = useState({
    countHospital: "0",
    quantityNews: "0",
    quantityUser: "0",
    nameDoctor: ""
  })
  const handleGetData = async () => {
    const NameDoctor = await getCurent(idAdmin)
    const CountHospital = await getAllBenhVien()
    const DataNews = await getAllNews();
    const DataUser = await getAllUser();
    const dataInfHospital=await getAllBenhVienLimit5();
    setDataHospital(dataInfHospital?.benhvien?.rows)
    setDataInfAdmin(prev => ({
      ...prev,
      nameDoctor: NameDoctor?.user?.name,
      countHospital: CountHospital?.benhvien?.count,
      quantityNews: DataNews?.Schedule?.count,
      quantityUser: DataUser?.user?.count
    }))
  }
  const columns = [
    {
      title: 'Tên bệnh viện',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      render: (text) => <p>{text}</p>,
   
    },
    {
      title: 'Ảnh',
      dataIndex: 'avatar',
      key: 'avatar',
      align: 'center',
      render: (text) => <Avatar src={`${text}`} size={60} />,
  
    },
    {
      title: 'Địa Chỉ',
      dataIndex: 'diaChi',
      key: 'diaChi',
      align: 'center',
      render: (diaChi) => (
        <Tooltip placement="topLeft" title={diaChi}>
          {diaChi}
        </Tooltip>
      ),
     
    },
    {
      title: 'Hotline',
      dataIndex: 'sdt',
      key: 'sdt',
      align: 'center',
      ellipsis: {
        showTitle: false,
      },
      render: (sdt) => (
        <Tooltip placement="topLeft" title={sdt}>
          {sdt}
        </Tooltip>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      align: 'center',
      ellipsis: {
        showTitle: false,
      },
      render: (email) => (
        <Tooltip placement="topLeft" title={email}>
          {email}
        </Tooltip>
      ),
    },
  ];
  useEffect(() => {
    handleGetData()
  }, [])
  return (
    <div>
      <p className='title_dashboard'>XIN CHÀO  {dataInfAdmin?.nameDoctor}</p>
      <Flex justify='space-between'>
        <Col span={6} className='box_headerDashboard' style={{ backgroundColor: '#0476D9' }} >
          <Link to={"/ds-benh-vien"}>
            <Flex className='box_headerDashboard_item' vertical  >
              <p className='title_headerDashBoard'>Số lượng bệnh viện</p>
              <Flex justify='space-between' align='center'>
                <p className='content_headerDashboard'>{dataInfAdmin?.countHospital}</p>
                <CalendarOutlined style={{ fontSize: '35px', color: '#fff' }} />
              </Flex>
            </Flex>
          </Link>
        </Col>
        <Col span={6} className='box_headerDashboard' style={{ backgroundColor: '#049DD9' }}>
          <Link to={"/ds-tin-tuc"}>
            <Flex className='box_headerDashboard_item' vertical >
              <p className='title_headerDashBoard'>Số lượng tin tức </p>
              <Flex justify='space-between' align='center'>
                <p className='content_headerDashboard'>{dataInfAdmin?.quantityNews}</p>
                <FileOutlined style={{ fontSize: '35px', color: '#fff' }} />
              </Flex>

            </Flex>
          </Link>
        </Col>
        <Col span={6} className='box_headerDashboard' style={{ backgroundColor: 'rgb(49 161 98)' }}>

          <Flex className='box_headerDashboard_item' vertical >
            <p className='title_headerDashBoard'>Số người đang sử dụng </p>
            <Flex justify='space-between' align='center'>
              <p className='content_headerDashboard'>{dataInfAdmin?.quantityUser}</p>
              <FileAddOutlined style={{ fontSize: '35px', color: '#fff' }} />
            </Flex>

          </Flex>

        </Col>

      </Flex>
      <div className="content_AdminDashboar" style={{ marginTop: '30px' }}>
        <p className='title_dashboard'>Top 5 bệnh viện đang sử dụng dịch vụ</p>
        <ConfigProvider
          theme={{
            components: {
              Table: {
                headerBg: '#9FDFFB',
                headerColor: '#000',
                borderColor: '#9FDFFB',
                borderRadius: 20,
                footerBg: '#9FDFFB',
                footerColor: '#000'
              },
            },
            token: {
              colorBgContainer: 'rgba(255, 255, 255, 0.5)',
              boxShadowSecondary: '0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05)'
            },
          }}
        >
          <Table
            columns={columns}
            dataSource={dataHospital}        
            bordered={true}
            style={{ boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.3)' }}
            pagination="none"
          />
         

        </ConfigProvider>
      </div>
    </div>
  )
}
