const dataChuyenKhoa=[
    {
        "id":'1',
        "description":"Khám tổng quan dạ dày",
        "name":"Dạ dày",
        "price":"120000",
        
    },
    {
        "id":'2',
        "description":"Khám tổng quan dạ dày",
        "name":"Khám trĩ",
        "price":"120000",
        
    },
    {
        "id":'3',
        "description":"Khám tổng quan dạ dày",
        "name":"Trĩ Ngoại",
        "price":"120000",
        
    },
    {
        "id":'4',
        "description":"Khám tổng quan dạ dày",
        "name":"Dạ dày",
        "price":"120000",
        
    },
    {
        "id":'5',
        "description":"Khám tổng quan dạ dày",
        "name":"Dạ dày",
        "price":"120000",
        
    },
    {
        "id":'6',
        "description":"Khám tổng quan dạ dày",
        "name":"Dạ dày",
        "price":"120000",
        
    },
]
export {dataChuyenKhoa}